<!-- Finder particular de Apds -->

<template>
  <div class="apd_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->       
        <base_Header 
          :componenteTipo="componenteTipo"
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>        

        <!-- Contenido -->
        <div class="contenedor conflex" style="width:800px"> 
          
          <!-- Filtro de Busqueda -->
          <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
          <div class="columna">
            <div class="conflex">
            
              <v-select
                v-bind="$select"
                v-model="schema.ctrls.idjefe.value"
                :label="schema.ctrls.idjefe.label"
                :items="jefeszona"
                :disabled="zona >0"
                item-value="id"
                item-text="name">            
              </v-select>

              <v-btn v-bind="$cfg.btn.busca" @click="buscarExec('buscarNombre')">
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              @keypress.13="buscarExec('buscarNombre')">                  
            </v-text-field>

            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.bajas.value"
              :label="schema.ctrls.bajas.label">
            </v-checkbox>           
          
          </div>

          <!-- Grid -->
          <base_Fgrid 
            :padre="stName" 
            :Entorno="Entorno" 
            dense
            @onEvent="event_Grid"
            style="width:100%">

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ }">         
              <div style="padding:10px 0 0 10px;">              
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:{id:0} })">
                </btracombi>
              </div>
            </template>
            

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>

      <!-- Mto como ventana modal -->      
      <v-dialog
        content-class="modal" persistent
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>   
    </div>
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  /* const btraextra = () => plugs.groute("btraextra.vue", "comp"); */  
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const apd_M = () => plugs.groute("apd_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, btraextra, apd_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    data() {
      return {
        schema:null,
        api:'apd_F',
        stName:'stFapds',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {},       
        jefeszona:[]
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "APDS";  
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "acepto", icono: "mdi-check-bold" },
          { accion: "bloqueo", icono: "mdi-lock" },
          { accion: "pdf", btn: "btn_pdf" },        
          { accion: "pego", texto: "PEGO" },
          { accion: "imp_prestos", texto: "IMP" }
        ];

        // obtengo items adicionales
        // this.itemsPrestacion = this.$store.state.datos_iniciales.tipo_presup;
        // this.itemsAno = this.getItemsAno();
                
        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width:'10%', slot:true },
            { text: "Nombre", value: "name", width: "60%" },
            { text: "Código", value: "codigo", width: "30%" }      
          ],

        };

        this.Entorno.grid.headers = this.set_headers();

        // items adicionales
        // coordinador de servicio
        this.jefeszona= this.$store.state.datos_iniciales.zonas;
        this.jefeszona.unshift({ id:"0", name:"( Selecciona )"});

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'apd_M';        
      },  


      ini_schemaCtrls() {
        this.schema.ctrls.idjefe.value= this.zona;
      }
/* 
     exec() { 
       this.btnReset('acepto', {styI:"color:red !important", disabled:true});
     } */

    }
  };
</script>
